import React from 'react';
import './Loading.css';

const Loading = () => {
    return (
        <div className="loading-container">
            <h3>Loading... </h3>
            <div className="loader">

            </div>
        </div>
    )
}

export default Loading


